<template>
  <div>
    <CRow>
    <CCol lg="7">
    <CNavbar light color="light" class="px-0">
      <h4> Division List</h4>
      <CForm inline>
         <b-input-group size="sm">
            <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        <CButton color="dark" size="sm" class="border-0">
          <CIcon name="cil-search" size="sm" />
        </CButton>
      </CForm>
      <CForm inline>
        <CButton
          color="outline-dark "
          class="my-2 my-sm-0 btn-out-dashed"
          type="submit"
          size="sm"
          @click="next_page"
          >+ Add New</CButton
        >
      </CForm>
    </CNavbar>
    <div class="table-wrapper py-3">
      <CRow>
        <CCol col="12" xl="12">
          <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="xs"
              show-empty
              small
              @filtered="onFiltered"
              >
            </b-table>
        </CCol>
      </CRow>
    </div>
     </CCol>
       <CCol lg="5">
        <AddDivision />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AddDivision from "./AddDivision";
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;

export default {
  name: "DivisionList",
  data() {
    return {
      items: [],
      filter:"",
      fields: [
        {
          key: "division_id",
          label: "Division Id",
          _classes: "font-weight-bold",
        },
        { key: "division",label: "Division" },
        { key: "school_id",label: "School" }
      ],
      activePage: 1,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    next_page(){
       this.$router.push({ name: "AddDivision"});
    },
    get_data(){
       var promise = apis.list_DivisionMasters().then( response => {
          this.items = response.data;
         // console.log(this.items);
         
      }).catch( errror => {

      })
    },
    // rowClicked(item, index) {
    //   this.$router.push({ path: `users/${index + 1}` });
    // },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
  components:{
    AddDivision
  },
  created(){
    this.get_data();
  }
};
</script>

<style></style>
